import {authenticator, Permission} from '@oegbv/ui-shared'
import * as React from 'react'
import {arPathPlusInfo} from '../../store/ar.paths'
import {ArLink} from './ArLink'

export function ExclusivePlusLink() {
  const isPlusUser = authenticator.hasPermission(Permission.ARFBR_PLUS)
  const isPremiumUser = authenticator.hasPermission(Permission.ARFBR_PREMIUM)
  if (isPlusUser || isPremiumUser) {
    return null
  }

  return (
    <sup>
      <ArLink name="Exklusiv in Arbeitsrecht für Betriebsräte" link={arPathPlusInfo()} accent />{' '}
    </sup>
  )
}
