import {Box, Col, ColRow, Flex, Grid, Text} from '@indoqa/style-system'
import {authenticator, Permission} from '@oegbv/ui-shared'
import * as React from 'react'
import {arPathBook, arPathContentResources, arPathSearch} from '../../store/ar.paths'
import {ArLink} from '../links/ArLink'
import {ExclusivePlusLink} from '../links/ExclusivePlusLink'
import {ArSearchInput} from '../search/ArSearchInput'

export const ArOverviewPanel = () => {
  const isPlusUser = authenticator.hasPermission(Permission.ARFBR_PLUS)
  const isPremiumUser = authenticator.hasPermission(Permission.ARFBR_PREMIUM)
  const isArfbrUser = isPlusUser || isPremiumUser

  const leitfadenBetriebsvereinbarungLink = isArfbrUser ? (
    <ArLink
      name="Leitfaden Betriebsvereinbarung"
      link={arPathBook(
        'Leitfaden Betriebsvereinbarung',
        '351877-0.1',
        '351877-0.1_pr351886_3007050',
        '51877-0.1_pr351886_3007050'
      )}
    />
  ) : (
    <ArLink name="Leitfaden Betriebsvereinbarung" link={arPathSearch('Leitfaden Betriebsvereinbarung')} />
  )

  return (
    <Box fullWidth>
      <Flex mb={28} pb={6} mt={16} justifyContent="center" fullWidth direction="column">
        <Box textAlign="center">
          <h1>Arbeitsrecht für Betriebsräte</h1>
        </Box>
        <Box textAlign="center">
          <p>Arbeitsrechtliche Fragen als Betriebsrat einfach und schnell lösen!</p>
        </Box>

        <Flex fullWidth center py={3}>
          <Box maxWidth={750} fullWidth>
            <ArSearchInput />
          </Box>
        </Flex>

        <Box textAlign="center">
          <Text pr={2}>
            <ArLink link={arPathContentResources()} name="Inhalte" />
          </Text>{' '}
          <Text pl={2}>
            <ArLink link={'/login'} name="Login" />
          </Text>
        </Box>

        <Box pt={12}>
          <h2>Ich suche Infos zum Thema</h2>
        </Box>
        <Grid fullWidth>
          <ColRow>
            <Col size={[6]}>
              <ul>
                <li>
                  <ArLink name="Pflegefreistellung" link={arPathSearch('pflegefreistellung')} />
                </li>
                <li>
                  <ArLink
                    name={`Arbeitskräfte${String.fromCharCode(173)}überlassung`}
                    link={arPathSearch('Arbeitskräfteüberlassung')}
                  />
                </li>
                <li>
                  <ArLink name="Lohn- und Sozialdumping" link={arPathSearch('Lohn- und Sozialdumping')} />
                </li>
              </ul>
            </Col>
            <Col size={[6]}>
              <ul>
                <li>
                  <ArLink name="Betriebsratsfonds" link={arPathSearch('Betriebsratsfonds')} />
                </li>
                <li>
                  <ArLink name="Datenschutz" link={arPathSearch('Datenschutz')} />
                </li>
                <li>
                  <ArLink name="Abfertigung" link={arPathSearch('Abfertigung')} />
                </li>
              </ul>
            </Col>
          </ColRow>
        </Grid>

        <Box pt={12}>
          <h2>
            Fragen und Antworten zum Thema Abfertigung <ExclusivePlusLink />
          </h2>
        </Box>
        {isArfbrUser && (
          <Box>
            <ul>
              <li>
                <ArLink
                  name="Wo ist die Abfertigung gesetzlich geregelt?"
                  link={arPathBook(
                    'Wo ist die Abfertigung gesetzlich geregelt',
                    '348641-18.0',
                    '348641-18.0_pr351374_3004838',
                    '348641-18.0_pr351375_3004839'
                  )}
                />
              </li>
              <li>
                <ArLink
                  name="Wonach bemisst sich die Höhe der Abfertigung?"
                  link={arPathBook(
                    'Wonach bemisst sich die Höhe der Abfertigung',
                    '348641-18.0',
                    '348641-18.0_pr351374_3004838',
                    '348641-18.0_pr351409_3004959'
                  )}
                />
              </li>
              <li>
                <ArLink
                  name="Wann wird die Abfertigung ausbezahlt?"
                  link={arPathBook(
                    'Wann wird die Abfertigung ausbezahlt',
                    '348641-18.0',
                    '348641-18.0_pr351374_3004838',
                    '348641-18.0_pr351395_3004911'
                  )}
                />
              </li>
            </ul>
          </Box>
        )}
        {!isArfbrUser && (
          <Box>
            <ul>
              <li>
                <ArLink
                  name="Wo ist die Abfertigung gesetzlich geregelt?"
                  link={arPathSearch('Wo ist die Abfertigung gesetzlich geregelt')}
                />
              </li>
              <li>
                <ArLink
                  name="Wonach bemisst sich die Höhe der Abfertigung?"
                  link={arPathSearch('Wonach bemisst sich die Höhe der Abfertigung')}
                />
              </li>
              <li>
                <ArLink
                  name="Wann wird die Abfertigung ausbezahlt?"
                  link={arPathSearch('Wann wird die Abfertigung ausbezahlt')}
                />
              </li>
            </ul>
          </Box>
        )}

        <Box pt={12}>
          <h2>
            Leitfaden Betriebsvereinbarungen <ExclusivePlusLink />
          </h2>
        </Box>
        <Box>
          Betriebsvereinbarungen sind das zentrale Instrument zur Mitbestimmung im Betrieb. Im „
          {leitfadenBetriebsvereinbarungLink}“ findest du alle Infos zu den unterschiedlichen Arten von
          Betriebsvereinbarungen und was du beim Abschluss einer Betriebsvereinbarung beachten solltest.
        </Box>

        <Box pt={12}>
          <h2>Arbeitsrecht für Betriebsräte</h2>
        </Box>
        <Box>
          <ul>
            <li>
              <ArLink
                name="Was ist Arbeitsrecht für Betriebsräte?"
                link="https://arbeitsrecht-fuer-betriebsraete.at/"
              />
            </li>
            <li>
              <ArLink
                name="Welche Vorteile bietet Arbeitsrecht für Betriebsräte?"
                link="https://arbeitsrecht-fuer-betriebsraete.at/vorteile/"
              />
            </li>
            <li>
              <ArLink
                name="Welche Inhalte stehen dir in Arbeitsrecht für Betriebsräte zur Verfügung?"
                link="https://digital.oegbverlag.at/arbeitsrecht-all"
              />
            </li>
            <li>
              <ArLink
                name="Was kostet Arbeitsrecht für Betriebsräte?"
                link="https://shop.oegbverlag.at/arbeitsrecht-fuer-betriebsraete-plus"
              />
            </li>
          </ul>
        </Box>

        <Box pt={12}>
          <h2>Deine Optionen</h2>
        </Box>
        <Box>
          <ul>
            <li>
              <ArLink
                name="Arbeitsrecht für Betriebsräte kostenlos testen"
                link="https://arbeitsrecht-fuer-betriebsraete.at/testzugang/"
              />
            </li>
            <li>
              <ArLink
                name="An einer Online-Präsentation von Arbeitsrecht für Betriebsräte teilnehmen"
                link="https://arbeitsrecht-fuer-betriebsraete.at/praesentation/"
              />
            </li>
            <li>
              <ArLink
                name="Mit dem Team von Arbeitsrecht für Betriebsräte Kontakt aufnehmen"
                link="https://arbeitsrecht-fuer-betriebsraete.at/kontakt/"
              />
            </li>
            <li>
              <ArLink
                name="Arbeitsrecht für Betriebsräte bestellen"
                link="https://shop.oegbverlag.at/arbeitsrecht-fuer-betriebsraete-plus"
              />
            </li>
          </ul>
        </Box>
      </Flex>
    </Box>
  )
}
