import {authenticator, Permission} from '@oegbv/ui-shared'
import {Availability} from '../../commons/types/SearchResult'

interface UserResourceAvailability {
  available: boolean
  requiredAvailability?: Availability
  denialInfo?: string
}

export const getResourceAvailabilityForUser = (resourceAvailability?: Availability): UserResourceAvailability => {
  const isPlusUser = authenticator.hasPermission(Permission.ARFBR_PLUS)
  const isPremiumUser = authenticator.hasPermission(Permission.ARFBR_PREMIUM)

  // premium user see everything
  if (isPremiumUser) {
    return {
      available: true,
      requiredAvailability: resourceAvailability,
      denialInfo: undefined,
    }
  }

  if (isPlusUser) {
    const isAvailable = resourceAvailability !== 'PREMIUM'
    return {
      available: isAvailable,
      requiredAvailability: resourceAvailability,
      denialInfo: `Exklusiv für Premium-Nutzer`,
    }
  }

  if (resourceAvailability === 'FREE') {
    return {
      available: true,
      requiredAvailability: resourceAvailability,
      denialInfo: undefined,
    }
  }

  return {
    available: false,
    requiredAvailability: resourceAvailability,
    // denialInfo: `Exklusiv in Arbeitsrecht für Betriebsräte ${resourceAvailability === 'PREMIUM' ? 'Premium' : 'Plus'}`,
    denialInfo: `Exklusiv in Arbeitsrecht für Betriebsräte`,
  }
}
